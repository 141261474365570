import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/bin/staticsites/ss-oryx/app-int/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`The `}<inlineCode parentName="p">{`<Title>`}</inlineCode>{` component is used to provide a title to a subsequent component (image, video, code block). The `}<inlineCode parentName="p">{`Title`}</inlineCode>{` should be used in favor of other techniques for bolded text (`}<inlineCode parentName="p">{`h4`}</inlineCode>{`s) to preserve page structure and heading hierarchy.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <Title mdxType="Title">This is a title</Title>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "30.555555555555554%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAYAAADDl76dAAAACXBIWXMAAAsTAAALEwEAmpwYAAABxUlEQVQY0x3MT0hTcQDA8d/ewxgG4mQqQi6k50LRjSJs0KGkltUhkrYio8XS1ALpUIcozYZJfyCIQLA6SGkho5WZEMZKGAkeNFc6PUQEZVpUU/faDrX3vv3o8OF7+wqlci1KZS5KhQ3FWY2ysUp2C6q2G7V8J6qjHtV+ElHYRGnBUTbn+3HZjrAj102d1YHXWoZ3TbFUKBUhVN96VP86VF8F4sBhyYfY34TY043Y24mltpecmpcIzyieqgiNG/ppcIa5WHSMq7btdBfs4kqeg668YqkEYQ9twx7ykH+5Dkt7O5aO81gu3EKcjSHORVHaZrE2phHNOv76JD2137nmXeWF6zoTWpBxZytvta3EtWqmNTfCNdTApqFDlD89hYg8RjwZJCc8Rkm/jn1gGXdfBv8d2HcPbt6AVx0wHIKF0yNkgj3oJ+6SPX4GM9CMEWhFBOa6CM53cnDuNmWzM5Qm4tS8/0TbJLRMwaUJk76YQe8bk+ioQeKZwfRzSD2agfsxjAfjmAMRzIeDUhjxOj3FWHqS6O8EIymdYV0nmsrwbtkkviIHSYOPPww+/DRY+pZl5etfkotZ/iysYn75hSFlPy9Ji//9A4LILAKbqMyBAAAAAElFTkSuQmCC')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Colors",
        "title": "Colors",
        "src": "/static/c0fc9864a6ce4ef42cef74f3f451329e/3cbba/colors.png",
        "srcSet": ["/static/c0fc9864a6ce4ef42cef74f3f451329e/7fc1e/colors.png 288w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/a5df1/colors.png 576w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/3cbba/colors.png 1152w", "/static/c0fc9864a6ce4ef42cef74f3f451329e/25c8a/colors.png 1592w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <h2>{`Code`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "path=components/Title/Title.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Title",
        "path": "components/Title/Title.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/master/packages/gatsby-theme-carbon/src/components/Title"
      }}>{`<Title>This is a title</Title>

![Colors](images/colors.png)
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      